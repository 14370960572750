import React from "react";

import imgBannerDesktop from "../../images/resource/banner.png";
import imgBannerTablet from "../../images/resource/banner-tablet.png";
import imgBannerMobile from "../../images/resource/banner-mobile.png";

const PromBanner = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <>
      <div className="containerPromo">
        {/* <img className="img-banner-desktop" src={imgBannerDesktop} onClick={() => window.open('https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/', '_blank')} />
        <img className="img-banner-tablet" src={imgBannerTablet} onClick={() => window.open('https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/', '_blank')} />
        <img className="img-banner-mobile" src={imgBannerMobile} onClick={() => window.open('https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/', '_blank')} /> */}
      </div>
    </>
  );
};

export default PromBanner;
