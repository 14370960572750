import React from "react";
import useResponsiveLink from "../../Hooks/useResponsiveLink";

const Band = () => {

  const deviceLink = useResponsiveLink()
  return (
    <div>
      <div className="band">
        {/* <p className="about-one_text">¡Sin cupos  disponibles!</p> */}
        <a
          className="btn-band"
          href={deviceLink}
          target="_blank"
          rel="noreferrer"
        >
          Descargar el programa
        </a>
      </div>
    </div>
  );
};

export default Band;
