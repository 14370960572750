import Navbar from "../../components/Navbar/Navbar";
import Hero from "../../components/hero/hero";
import Band from "../../components/Band/Band";
/* import Prueba from '../../components/Prueba/Prueba'; */
import About from "../../components/about/about";
import FunFact from "../../components/FunFact";
import Speakers from "../../components/Speakers/Speakers";
/* import Features from '../../components/Features'; */
/* import EventsSection from '../../components/EventsSection/EventsSection'; */
import GallerySection from "../../components/GallerySection/GallerySection";
import Pricing from "../../components/Pricing/Pricing";
import PricingNew from "../../components/PricingNew/PricingNew";
/* import VideoArea from '../../components/VideoArea/VideoArea'; */
import PartnerSection from "../../components/PartnerSection";
/* import Subscribe from '../../components/Subscribe/Subscribe'; */
/* import BlogSection from '../../components/BlogSection/BlogSection'; */
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Hubspot from "../../components/Hubspot/Hubspot";
import PromBanner from "../../components/PromBanner/PromBanner";
import Calendar from "../../components/Calendar/Calendar";
import Certifications from "../../components/Certifications/Certifications";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import avatarW from "../../images/resource/avatar-whatsapp.png";
import '../../css/WhatsAppStyles.css'
import Prueba from "../../components/Prueba/Prueba";
import Tester from "../../components/Tester/Tester";


const HomePage = () => {
  return (
    <main className="content-home">
      <Navbar />
      <Prueba />
      <Band />
      {/* <Prueba /> */}
      <About />
      <Certifications />
      <FunFact />
      <Speakers />
      {/* <Features /> */}
      {/* <EventsSection /> */}
      <GallerySection />
      <Calendar />
      {/* <Pricing /> */}
      <PricingNew />
      <Hubspot />
      {/* <VideoArea /> */}
      <PartnerSection />
      {/* <BlogSection /> */}
      {/*  <Subscribe /> */}
      <PromBanner />
      <FloatingWhatsApp
        phoneNumber="573188393243"
        accountName="AMF"
        avatar={avatarW}
        statusMessage="Te responderemos en unos minutos"
        chatMessage="¡Hola! 👋 ¿En qué puedo ayudarte?"
        placeholder="Escribe tu mensaje..."
        notificationSound = {true}
        allowClickAway
        allowEsc
      />
      <Footer />
      <Scrollbar />
    </main>
  );
};
export default HomePage;
