import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom'
import Events from '../../api/Events';

const EventsSection2 = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <section className="speakers-two" id='events'>
            <div className="auto-container">
                <div className="sec-title centered">
                    <div className="sec-title_title">Thought Leadership</div>
                    <h2 className="sec-title_heading">Join The World's Leading <br /> Companies at Technology Conference</h2>
                </div>
                <div className="inner-container">
                    <div className="speaker-info-tabs">
                        <div className="speaker-tabs tabs-box">
                            <ul>
                                <Nav tabs className="tab-btns tab-buttons clearfix">
                                    {Events.slice(0, 3).map((event, i) => (
                                        <NavItem className='tab-btn' key={i}>
                                            <NavLink
                                                className={classnames({ active: activeTab === event.Id })}
                                                onClick={() => { toggle(event.Id); }}
                                            >
                                                <span>{event.date}</span> {event.day}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                            </ul>
                            <div className="tabs-content">
                                <div className="content">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId='1'>
                                            {Events.slice(0, 3).map((event, i) => (
                                                <div className={`leadership-two_block ${event.Sclass}`} key={i}>
                                                    <div className="leadership-two_block-inner">
                                                        <div className="leadership-two_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-two_block-icon fi flaticon-voice"></div>
                                                        </div>
                                                        <div className="leadership-two_block-content">
                                                            <div className="leadership-two_block-time">{event.time}</div>
                                                            <div className="leadership-two_block-name">{event.speaker} <span>Art Critic</span></div>
                                                            <h3 className="leadership-two_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                            <div className="leadership-two_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </TabPane>
                                        <TabPane tabId='2'>
                                            {Events.slice(2, 4).map((event, eb2) => (
                                                <div className={`leadership-two_block ${event.Sclass}`} key={eb2}>
                                                    <div className="leadership-two_block-inner">
                                                        <div className="leadership-two_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-two_block-icon fi flaticon-voice"></div>
                                                        </div>
                                                        <div className="leadership-two_block-content">
                                                            <div className="leadership-two_block-time">{event.time}</div>
                                                            <div className="leadership-two_block-name">{event.speaker} <span>Art Critic</span></div>
                                                            <h3 className="leadership-two_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                            <div className="leadership-two_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </TabPane>
                                        <TabPane tabId='3'>
                                            {Events.slice(1, 4).map((event, eb3) => (
                                                <div className={`leadership-two_block ${event.Sclass}`} key={eb3}>
                                                    <div className="leadership-two_block-inner">
                                                        <div className="leadership-two_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-two_block-icon fi flaticon-voice"></div>
                                                        </div>
                                                        <div className="leadership-two_block-content">
                                                            <div className="leadership-two_block-time">{event.time}</div>
                                                            <div className="leadership-two_block-name">{event.speaker} <span>Art Critic</span></div>
                                                            <h3 className="leadership-two_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                            <div className="leadership-two_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default EventsSection2;