import React from "react";
/* import imgVer from '../../images/diplIFMvertical.png' */
import imgVer2 from '../../images/diplIFMvertical2.png'
/* import modality from '../../images/modality.png' */
import modality2 from '../../images/modality2.png'


const PricingNew = () => {
  return (
    <>
      <div className="container-pricing container-rules" >
        <div className="pricing-header">
          <p id="section2">ASEGURA TU LUGAR</p>
          <h2>Paquetes de estudio</h2>
        </div>
        <div className="pricing-container">
          {/* <div className="pricing-card">
            <h3 className="price-advisor1">Precio válido del <span> 5 de enero <br/> al 6 de marzo</span> de 2024</h3>
            <div className="card-price card-price_blue">
              <p className="plan1">Early bird</p>
              <img className="first-img-price" src={imgVer} alt="" />
              <img className="second-img-price" src={modality} alt="" />
              <p className="disacount-price">USD $ 3.400</p>
              <p className="aliance-price">gracias a nuestra alianza entre AMF y IFM</p>
              
              
              <p className="disclaimer">
              * El precio final en moneda local dependerá del tipo de cambio de su entidad financiera (conversión USD - dólares americanos vs. moneda local de pago)
              </p>
            </div>
          </div> */}
          <div className="pricing-card">
            <h3 className="price-advisor2">
              Precio válido del <span>6 de marzo de 2024</span><br/> al cierre de inscripciones.
            </h3>
            <div className="card-price card-price_aqua">
              <p className="plan2">Sold Out</p>
              <img className="first-img-price" src={imgVer2} alt="" />
              <img className="second-img-price" src={modality2} alt="" />
              <p className="disacount-price">USD $ 3.400</p>
              <p className="aliance-price">gracias a nuestra alianza entre AMF y IFM</p>
              <p className="real-price">{/* USD $ 1.640  */} Sold Out</p>
              {/* <a href="https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/" target="_blank" rel="noreferrer" class="theme-btn btn-style-three"><span class="btn-wrap"><span class="text-one">Comprar</span><span class="text-two">Comprar</span></span></a> */}
              <p className="disclaimer">
              * El precio final en moneda local dependerá del tipo de cambio de su entidad financiera (conversión USD - dólares americanos vs. moneda local de pago)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingNew;
