import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Teams from '../../api/Teams'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import bg from '../../images/background/pattern-27.png'
import bg2 from '../../images/background/faq.jpg'


const SpeakerSinglePage = (props) => {
    const { slug } = useParams()

    const TeamDetails = Teams.find(item => item.slug === slug)


    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }



    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={TeamDetails.name} pagesub='Team Single' pageTitle2={'Team Single'} />
            <section className="team-single-section">
                <div className="speaker-detail">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="speaker-detail_author-column col-lg-4 col-md-6 col-sm-12">
                                <div className="speaker-detail_author-inner">
                                    <div className="speaker-detail_author-pattern" style={{ backgroundImage: `url(${bg})` }}></div>
                                    <div className="speaker-detail_author-image">
                                        <img src={TeamDetails?.tImg} alt="" />
                                        <div className="speaker-detail_social-list">
                                            <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="facebook fa fa-facebook"></Link>
                                            <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="youtube fi flaticon-youtube"></Link>
                                            <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="instagram fa fa-instagram"></Link>
                                            <Link onClick={ClickHandler} to={"/speaker-details/Jenson-Button"} className="twitter fa fa-twitter"></Link>
                                        </div>
                                    </div>
                                    <div className="speaker-detail_author-content">
                                        <h4 className="speaker-detail_title">{TeamDetails?.name}</h4>
                                        <div className="speaker-detail_designation">{TeamDetails?.title}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-column col-lg-8 col-md-12 col-sm-12">
                                <h2 className="speaker-detail_subtitle">Personal Information</h2>
                                <p>Anna assumed the role of Chief Product Officer in July 2017 and leads the product team, which designs, builds and optimizes the Netflix experience. Previously, Anna was International Development Officer for Netflix, responsible for the global partnerships with consumer electronics.</p>
                                <p>Prior to joining Netflix in 2008, Anna was senior vice president of consumer electronics products for Macrovision Solutions Corp. (later renamed to Rovi Corporation) and previously held positions at digital entertainment software provider, Mediabolic Inc., Red Hat Network, the provider of Linux and Open Source technology, and online vendor Wine.</p>
                                <h3 className="speaker-detail_subtitle">Contact info</h3>
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <ul className="speaker-detail_info">
                                            <li><span>Speciality:</span> Product Designer</li>
                                            <li><span>Experience:</span> 10 Years</li>
                                            <li><span>Phone:</span> 700.123.456.789</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <ul className="speaker-detail_info">
                                            <li><span>Company:</span> Netflix</li>
                                            <li><span>Email:</span> angelina888@mail.com</li>
                                            <li><span>Fax:</span> 700.123.456.789</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="speaker-schedule">
                    <div className="auto-container">
                        <h4 className="speaker-detail_subtitle">Speeches By: {TeamDetails?.name}</h4>
                        <div className="row clearfix">

                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="event-block_three">
                                    <div className="event-block_three-inner">
                                        <div className="event-block_three-room">
                                            <div className="event-block_three-icon flaticon-clock-2"></div>
                                            <div className="event-block_three-room_number">Room#2 <span>10:00am -12:00pm</span></div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <div className="event-block_three-date">2022-11-15</div>
                                            <div className="event-block_three-day">First Day</div>
                                        </div>
                                        <h3 className="event-block_three-heading"><Link onClick={ClickHandler} to="/">How to Start Freelancing</Link></h3>
                                        <div className="event-block_three-text">All the generators on the Internet tend to repeat predefined chunk as necessary, making this the first true generator on the Internet.</div>
                                        <div className="event-block_three-place"><strong>Place:</strong> 5 Universal Principles of Company Growth</div>
                                    </div>
                                </div>

                                <div className="event-block_three">
                                    <div className="event-block_three-inner">
                                        <div className="event-block_three-room">
                                            <div className="event-block_three-icon flaticon-clock-2"></div>
                                            <div className="event-block_three-room_number">Room#1 <span>10:00am -12:00pm</span></div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <div className="event-block_three-date">2022-11-15</div>
                                            <div className="event-block_three-day">2nd Day</div>
                                        </div>
                                        <h3 className="event-block_three-heading"><Link onClick={ClickHandler} to="/">Design for Startups</Link></h3>
                                        <div className="event-block_three-text">All the generators on the Internet tend to repeat predefined chunk as necessary, making this the first true generator on the Internet.</div>
                                        <div className="event-block_three-place"><strong>Place:</strong> 5 Universal Principles of Company Growth</div>
                                    </div>
                                </div>
                                <div className="event-block_three">
                                    <div className="event-block_three-inner">
                                        <div className="event-block_three-room">
                                            <div className="event-block_three-icon flaticon-clock-2"></div>
                                            <div className="event-block_three-room_number">Room#3 <span>10:00am -12:00pm</span></div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <div className="event-block_three-date">2022-11-15</div>
                                            <div className="event-block_three-day">3rd Day</div>
                                        </div>
                                        <h3 className="event-block_three-heading"><Link onClick={ClickHandler} to="/">Finance For Freelancers</Link></h3>
                                        <div className="event-block_three-text">All the generators on the Internet tend to repeat predefined chunk as necessary, making this the first true generator on the Internet.</div>
                                        <div className="event-block_three-place"><strong>Place:</strong> 5 Universal Principles of Company Growth</div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="speaker-schedule_form" style={{ backgroundImage: `url(${bg2})` }}>
                                    <div className="speaker-schedule_title-box">
                                        <div className="speaker-schedule_title">For Contact That Speaker</div>
                                        <h3 className="speaker-schedule_heading">Quick Contact</h3>
                                    </div>
                                    <div className="faq-form">
                                        <form method="post" onSubmit={SubmitHandler}>

                                            <div className="form-group">
                                                <input type="text" name="username" placeholder="Name" required="" />
                                            </div>

                                            <div className="form-group">
                                                <input type="text" name="username" placeholder="Email" required="" />
                                            </div>

                                            <div className="form-group">
                                                <input type="text" name="phone" placeholder="Phone Number" required="" />
                                            </div>

                                            <div className="form-group">
                                                <textarea className="" name="message" placeholder="Enter your Massage*"></textarea>
                                            </div>

                                            <div className="form-group">
                                                <div className="button-box">
                                                    <button className="theme-btn submit-btn">
                                                        Submit Now
                                                    </button>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default SpeakerSinglePage;
