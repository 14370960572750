import React from 'react'
import abimg from '../../images/resource/about-1.png'
import bg1 from '../../images/background/pattern-15.png'
import bg2 from '../../images/background/pattern-16.png'
import bg3 from '../../images/background/pattern-18.png'
import bg4 from '../../images/background/pattern-17.png'


const About2 = (props) => {
    return (
        <section className="about-two" id="about">
            <div className="about-two_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
            <div className="about-two_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div>
            <div className="about-two_pattern-three" style={{ backgroundImage: `url(${bg3})` }}></div>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="about-two_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-two_content-inner">
                            <div className="sec-title title-anim">
                                <div className="sec-title_title">Introduction</div>
                                <h2 className="sec-title_heading">Know More About Our <br/> Grand Event</h2>
                                <div className="sec-title_text">Consequat sociosqu officiis aute ridiculus repellat in ali quip, me us socios veritat is cubilia soluta? Facibus ipsam, incidunt cras.</div>
                            </div>
                            <div className="about-two_options">
                                <ul className="about-two_options-list">
                                    <li><span className="icon fi flaticon-checked-1"></span>Lusto tenetur temporibus repellendus aspernatur, blandit ullam cupidatat quisquam lacinia.</li>
                                    <li><span className="icon fi flaticon-checked-1"></span>Minima mattis laudantium nobis odit explicabo sapien nunc. Reprehenderit molestiae.</li>
                                </ul>
                            </div>
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="about-two_signature">micheal clark</div>
                                <div className="about-two_author">
                                    MD WILLIAM HOUSTON <span> Event Organiser</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="about-two_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-two_image-inner">
                            <div className="about-two_image titlt" data-tilt data-tilt-max="5">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="about-two_counter" style={{ backgroundImage: `url(${bg4})` }}>
                                <div className="about-two_counter-words"><span className="odometer" data-count="35"></span>35K</div>
                                <div className="about-two_counter-text">We’ve help our <span>street children</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;