import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

import pImg1 from '../../images/gallery/1.jpg'
import pImg2 from '../../images/gallery/2.jpg' 
import pImg3 from '../../images/gallery/3.jpg'
import pImg4 from '../../images/gallery/4.jpg'
import pImg5 from '../../images/gallery/5.jpg'
import pImg6 from '../../images/gallery/6.jpg'
import pImg7 from '../../images/gallery/7.jpg'
import pImg8 from '../../images/gallery/8.jpg'
import React from "react";

const images = [
    pImg1,
    pImg2, 
    pImg3,
    pImg4,
    pImg5,
    pImg6,
    pImg7,
    pImg8,
]

const GallerySection = (props) => {

    const [open, setOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState([   
        { src: pImg1 },
        { src: pImg2 }, 
        { src: pImg3 }, 
        { src: pImg4 }, 
        { src: pImg5 },
        { src: pImg6 },
        { src: pImg7 },
        { src: pImg8 }
    ]);

    /* const [selectedImage, setSelectedImage] = useState(null) */

    const selectedImageId =(imageId)=>{

    const index = imageOpen.findIndex(item => item === imageId)

    console.log(index);
    if(index >-1){
        /* const [item] = imageOpen.splice(index,1) */
        const item = imageOpen.splice(index,1)[0] 
        imageOpen.unshift(item)
    }
    console.log(JSON.stringify(imageOpen));

    setImageOpen(imageOpen)
    setOpen(true)
    }

    const closeModal =()=>{
        setImageOpen([   
            { src: pImg1 },
            { src: pImg2 }, 
            { src: pImg3 }, 
            { src: pImg4 }, 
            { src: pImg5 },
            { src: pImg6 },
            { src: pImg7 },
            { src: pImg8 }
        ])

        setOpen(false)
    }

    return (
        <section className="gallery-one">
            <div className="outer-container">
                <div className="clearfix">
                    {imageOpen.map((item, pitem) => (
                        <div className="gallery-one_block col-lg-3 col-md-4 col-sm-6" key={pitem}>
                            <div className="gallery-one_block-inner">
                                <div className="gallery-one_block-image" onClick={() => selectedImageId(item)}>
                                    <img src={item.src} alt="" />
                                    <div className="gallery-one_block-overlay">
                                        <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <Lightbox
                open={open}
                close={() => closeModal()}
                slides={
                    imageOpen
                    /* { src: pImg1 },
                    { src: pImg2 }, 
                    { src: pImg3 }, 
                    { src: pImg4 }, 
                    { src: pImg5 },
                    { src: pImg6 },
                    { src: pImg7 },
                    { src: pImg8 } */
                }
                plugins={[Zoom]}
            />
        </section>
    )
}

export default GallerySection; 