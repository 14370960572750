import React from 'react';
import ContactForm from '../ContactFrom'
import sImg from '../../images/resource/contact.png'

const Contactpage3 = () => {

    return (

        <div>
            <section className="contact-page-section-two">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="map-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="map-outer">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805184.6331292129!2d144.49266890254142!3d-37.97123689954809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2s!4v1574408946759!5m2!1sen!2s" allowFullScreen=""></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="info-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <ul className="info">
                                    <li><span>Email</span>Info@gmail.com</li>
                                    <li><span>Phone number</span>(+678) 234 43333 56</li>
                                    <li><span>Office Address</span>Unit 6C Boundary Medical Land, <br /> Millfield Road, Bolton, BL2 6QY</li>
                                    <li><span>Send us mail</span>To discuss us about work with us <br /> you can contact us.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-form-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="form-column col-lg-7 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <h3>Send us a message</h3>

                                <div className="default-form contact-form">
                                    <ContactForm/>
                                </div>

                            </div>
                        </div>
                        <div className="image-column col-lg-5 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="image wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <img src={sImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )

}

export default Contactpage3;
