import timg1 from "../images/resource/1.png";
import timg2 from "../images/resource/2.png";
import timg3 from "../images/resource/3.png";
import timg4 from "../images/resource/4.png";
import timg5 from "../images/resource/5.png";
import timg6 from "../images/resource/6.png";
import timg7 from "../images/resource/7.png";
import timg8 from "../images/resource/8.png";
import timg9 from "../images/resource/9.png";
import timg10 from "../images/resource/10.png";
import timg11 from "../images/resource/11.png";
import timg12 from "../images/resource/12.png";
import timg13 from "../images/resource/13.png";
import timg14 from "../images/resource/14.png";
import timg15 from "../images/resource/15.png";
import timg16 from "../images/resource/16.png";

import flag1 from "../images/resource/flag1.png";
import flag2 from "../images/resource/flag2.png";
import flag3 from "../images/resource/flag3.png";
import flag4 from "../images/resource/flag4.png";
import flag5 from "../images/resource/flag5.png";
import flag6 from "../images/resource/flag6.png";

import popup1AH from "../images/resource/popup1AH.png";
import popup1AVM from "../images/resource/popup1AV.png";
import popup1 from '../images/resource/popup1.png'
import popup1M from '../images/resource/popupMobile1.png'
import popup2 from "../images/resource/popup2.png";
import popup2M from "../images/resource/popupMobile2.png";
import popup3 from "../images/resource/popup3.png";
import popup3M from "../images/resource/popupMobile3.png";
import popup4 from "../images/resource/popup4.png";
import popup4M from "../images/resource/popupMobile4.png";
import popup5 from "../images/resource/popup5.png";
import popup5M from "../images/resource/popupMobile5.png";
import popup6 from "../images/resource/popup6.png";
import popup6M from "../images/resource/popupMobile6.png";
import popup7 from "../images/resource/popup7.png";
import popup7M from "../images/resource/popupMobile7.png";
import popup8 from "../images/resource/popup8.png";
import popup8M from "../images/resource/popupMobile8.png";
import popup9 from "../images/resource/popup9.png";
import popup9M from "../images/resource/popupMobile9.png";
import popup10 from "../images/resource/popup10.png";
import popup10M from "../images/resource/popupMobile10.png";
import popup11 from "../images/resource/popup11.png";
import popup11M from "../images/resource/popupMobile11.png";
import popup12 from "../images/resource/popup12.png";
import popup12M from "../images/resource/popupMobile12.png";
import popup13 from "../images/resource/popup13.png";
import popup13M from "../images/resource/popupMobile13.png";
import popup14 from "../images/resource/popup14.png";
import popup14M from "../images/resource/popupMobile14.png";
import popup15 from "../images/resource/popup15.png";
import popup15M from "../images/resource/popupMobile15.png";
import popup16 from "../images/resource/popup16.png";
import popup16M from "../images/resource/popupMobile16.png";

/* import tsimg1 from '../images/resource/team-1.jpg' */
import tsimg2 from "../images/resource/team-2.jpg";
import tsimg3 from "../images/resource/team-3.jpg";
import tsimg4 from "../images/resource/team-4.jpg";
import tsimg5 from "../images/resource/team-5.jpg";
import tsimg6 from "../images/resource/team-6.jpg";
import tsimg7 from "../images/resource/team-15.jpg";
import tsimg8 from "../images/resource/team-16.jpg";

const Teams = [
  {
    Id: "14",
    tImg: timg14,
    /*  tsImg: tsimg9, */
    name: "Monique Class",
    slug: "Monique Class",
    title: "Enfermera familiar",
    flag: flag6,
    popup: popup14,
    popupM: popup14M,
  },
  {
    Id: "12",
    tImg: timg12,
    /*  tsImg: tsimg9, */
    name: "Dan Lukaczer",
    slug: "Dan Lukaczer",
    title: "Médico naturópata",
    flag: flag6,
    popup: popup12,
    popupM: popup12M,
  },
  {
    Id: "2",
    tImg: timg1,
    /* tsImg: tsimg2, */
    name: "Luc Lemaire",
    slug: "Luc Lemaire",
    title: "Médico osteópata funcional",
    flag: flag1,
    popup: popup1AH,
    popupM: popup1AVM,
  },
  {
    Id: "15",
    tImg: timg15,
    /*  tsImg: tsimg9, */
    name: "Patrick Hanaway",
    slug: "Patrick Hanaway",
    title: "Médico familiar",
    flag: flag6,
    popup: popup15,
    popupM: popup15M,
  },
  {
    Id: "10",
    tImg: timg10,
    /*  tsImg: tsimg9, */
    name: "Elizabeth Boham",
    slug: "Elizabeth Boham",
    title: "Médica familiar",
    flag: flag6,
    popup: popup10,
    popupM: popup10M,
  },
  {
    Id: "11",
    tImg: timg11,
    /*  tsImg: tsimg9, */
    name: "Michael Stone",
    slug: "Michael Stone",
    title: "Médico familiar",
    flag: flag6,
    popup: popup11,
    popupM: popup11M,
  },
  {
    Id: "16",
    tImg: timg16,
    /*  tsImg: tsimg9, */
    name: "Robert Rountree",
    slug: "Robert Rountree",
    title: "Médico familiar",
    flag: flag6,
    popup: popup16,
    popupM: popup16M,
  },
  {
    Id: "2",
    tImg: timg2,
    /* tsImg: tsimg2, */
    name: "Javier Galvis",
    slug: "Javier Galvis",
    title: "Médico internista",
    flag: flag2,
    popup: popup2,
    popupM: popup2M,
  },
  {
    Id: "13",
    tImg: timg13,
    /*  tsImg: tsimg9, */
    name: "Robert Luby",
    slug: "Robert Luby",
    title: "Médico familiar",
    flag: flag6,
    popup: popup13,
    popupM: popup13M,
  },
  {
    Id: "5",
    tImg: timg5,
    /* tsImg: tsimg5, */
    name: "Rossy Silva",
    slug: "Rossy Silva",
    title: "Médica cirujana",
    flag: flag3,
    popup: popup5,
    popupM: popup5M,
  },
  {
    Id: "9",
    tImg: timg9,
    /*  tsImg: tsimg9, */
    name: "Pedro Carrera",
    slug: "Pedro Carrera",
    title: "Dietista, nutricionista ",
    flag: flag5,
    popup: popup9,
    popupM: popup9M,
  },
  {
   Id: "3",
   tImg: timg3,
   /*  tsImg: tsimg3, */
   name: "Johanna Rodríguez",
   slug: "Johanna Rodríguez",
   title: "Médica cirujana",
   flag: flag2,
   popup: popup3,
   popupM: popup3M,
 },
  {
    Id: "4",
    tImg: timg4,
    /* tsImg: tsimg4, */
    name: "Rocío Herrera",
    slug: "Rocío Herrera",
    title: "Médica cirujana",
    flag: flag2,
    popup: popup4,
    popupM: popup4M,
  },
  {
   Id: "6",
   tImg: timg1,
   /* tsImg: tsimg2, */
   name: "Luc Lemaire",
   slug: "Luc Lemaire",
   title: "Médico osteópata funcional",
   flag: flag1,
   popup: popup1,
   popupM: popup1M,
 },
  {
    Id: "7",
    tImg: timg6,
    /* tsImg: tsimg6, */
    name: "Sergio Hernández",
    slug: "Sergio Hernández",
    title: "Médico cirujano ",
    flag: flag4,
    popup: popup6,
    popupM: popup6M,
  },
  {
   Id: "9",
   tImg: timg8,
   /* tsImg: tsimg8, */
   name: "Sebastián Castrillón",
   slug: "Sebastián Castrillón",
   title: "Médico cirujano ",
   flag: flag2,
   popup: popup8,
   popupM: popup8M,
 },
  {
    Id: "8",
    tImg: timg7,
    /* tsImg: tsimg7, */
    name: "Aldo patiño",
    slug: "Aldo patiño",
    title: "Médico cirujano ",
    flag: flag2,
    popup: popup7,
    popupM: popup7M,
  },
];

export default Teams;
