import eimg1 from '../images/resource/leadership-1.jpg'
import eimg2 from '../images/resource/leadership-2.jpg'
import eimg3 from '../images/resource/leadership-3.jpg'
import eimg4 from '../images/resource/leadership-4.jpg'


const Events = [
    {
        Id: '1',
        eTitle: 'Registration For Opening Workshop', 
        slug:'Registration-For-Opening-Workshop',
        eImg:eimg1,
        day:'First Day',
        date:'JANUARY 5, 2022',
        time:'9:00 am -- 11:00 am',
        speaker:'Paul Wilson',
        Icon:'flaticon-voice',
        des:'We’re inviting the top creatives in the tech industry from all over the world to come learn, grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures',
    },
    {
        Id: '2',
        eTitle: 'Modern Marketing Summit Sydney', 
        slug:'Modern-Marketing-Summit-Sydney',
        eImg:eimg2,
        day:'Second Day',
        date:'JANUARY 6, 2022',
        time:'10:00 am -- 12:00 am',
        speaker:'Ashli Scroggy',
        Icon:'flaticon-voice',
        Sclass:'style-two',
        des:'We’re inviting the top creatives in the tech industry from all over the world to come learn, grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures',
    },
    {
        Id: '3',
        eTitle: 'Introduction About Speakers', 
        slug:'Introduction-About-Speakers',
        eImg:eimg3,
        day:'Thired Day',
        date:'JANUARY 7, 2022',
        time:'1:00 am -- 4:00 am',
        speaker:'Robert Gates',
        Icon:'flaticon-voice',
        des:'We’re inviting the top creatives in the tech industry from all over the world to come learn, grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures',
    },
    {
        Id: '4',
        eTitle: 'Modern SEO Summit Sydney 2023', 
        slug:'Modern-SEO-Summit-Sydney-2023',
        eImg:eimg4,
        day:'Forth Day',
        date:'JANUARY 8, 2022',
        time:'3:00 am -- 6:00 am',
        speaker:'Paul Wilson',
        Sclass:'style-two',
        Icon:'flaticon-voice',
        des:'We’re inviting the top creatives in the tech industry from all over the world to come learn, grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures',
    },
]

export default Events;