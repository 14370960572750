import React, { Fragment } from 'react';
import Navbar4 from '../../components/Navbar4/Navbar4'
import Hero3 from '../../components/hero3/hero3';
import Business from '../../components/Business/Business';
import FunFact3 from '../../components/FunFact3/FunFact3';
import WhyAttendS2 from '../../components/WhyAttendS2/WhyAttendS2';
import Speakers3 from '../../components/Speakers3/Speakers3';
import EventsSection3 from '../../components/EventsSection3/EventsSection3';
import Testimonial from '../../components/Testimonial/Testimonial';
import Pricing3 from '../../components/Pricing3/Pricing3';
import PartnerSection2 from '../../components/PartnerSection2';
import BlogSection3 from '../../components/BlogSection3/BlogSection3';
import GallerySectionS2 from '../../components/GallerySectionS2/GallerySectionS2';
import Footer3 from '../../components/footer3/Footer3';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePageOnePage3 = () => {
    return (
        <Fragment>
            <Navbar4 />
            <Hero3 />
            <WhyAttendS2 />
            <Business />
            <FunFact3 />
            <Speakers3 />
            <EventsSection3 />
            <Testimonial />
            <Pricing3 />
            <BlogSection3 />
            <PartnerSection2 />
            <GallerySectionS2 />
            <Footer3 />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePageOnePage3;