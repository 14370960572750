import React from "react";
import { Link } from "react-router-dom";
import Logo from '../../images/logoVertical.png'
import icon1 from '../../images/icon1.png'
import icon2 from '../../images/icon2.png'
import icon3 from '../../images/icon3.png'


const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
}

  
  return (
    <footer className="main-footer">
      <div className="container-background">
        <div className="extract">
          <p>Academia de Medicina Funcional, un espacio educativo <br/> digital creado para toda la comunidad de <br/>profesionales de la salud.</p>
        </div>
        <div className="vertical-logo">
        <div className="logo-box">
          <div className="logo">
            <Link onClick={ClickHandler} to="/home">
              <img src={Logo} alt="" title="" />
            </Link>
          </div>
        </div>
        </div>
        <div className="contact">
        <h3>Contáctanos</h3>
          <p><img className="icon1" src={icon1} alt="Phone Icon" /><a href="tel:+57 318 839 3243" target="_blank" rel="noopener noreferrer"> +57 318 839 3243</a></p>
          <p><img className="icon1" src={icon2} alt="Email Icon" /><a href="mailto:contacto@academiademedicinafuncional.com" target="_blank" rel="noopener noreferrer"> contacto@academiademedicinafuncional.com</a></p>
          <p><img className="icon1" src={icon3} alt="Webpage Icon" /><a href="https://academiademedicinafuncional.info/amfrec/" target="_blank" rel="noopener noreferrer"> academiademedicinafuncional.info</a></p>
        </div>
      </div>
      <div className="copy">
        <p>Academia de Medicina Funcional 2023 |</p>
        <p> Todos los Derechos Reservados</p>
      </div>
    </footer>
  );
};

export default Footer;
