import React from 'react'

const Hubspot = () => {
  return (
    <>
        {/* <div className="hubspotContainer">
            <a href='https://share.hsforms.com/1D4c9p5k9SoKamln28jXwPge92zh' target='_blank' rel='noopener noreferrer' className="theme-btn btn-style-one variant">
            <span className="btn-wrap">
                <span className="text-one">Si deseas más información, déjanos tus datos</span>   
                <span className="text-two">Si deseas más información, déjanos tus datos</span> 
                </span>             
            </a>
        </div> */}
    </>
  )
}

export default Hubspot