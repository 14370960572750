import React, { useState, useEffect } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogContent } from "@mui/material";
import nImg from "../../images/resource/mainpopup.png";


const App = () => {
  const [open, setOpen] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(true)

  const SubmitHandler = (e) => {
    e.preventDefault();
  }

  function goToForm(){
    window.location.href ='#section4'
  }
   
  function clickHandler(){
    setOpen(false)
    goToForm()
  }
  return (
    <div className="App" id='scrool'>
      <AllRoute />
      <ToastContainer />

      

      <Dialog open={open}>
        <DialogContent>
          <div className="newsletter-popup-area-section">
            <div className="newsletter-popup-area">
              <div className="newsletter-popup-ineer">
                <div className="close-sidebar-widget close-button newsletter-close-btn">
                  <span onClick={() => setOpen(false)} className="close-btn fi flaticon-cancel-1"></span>
                </div>
                <div className="img-holder">
                  <img src={nImg} alt="" />
                </div>
                <div className="detail-box nueva">
                  <h3>Deja tus datos y conoce nuestras ofertas académicas</h3>
                  <div className="newsletter-box">
                    <form method="post" onSubmit={SubmitHandler}>
                      <div className="form-group d-flex flex-wrap">
                        {isPopupVisible &&(
                          <>
                          
                          <h3 style={{fontSize:'60px', color:'gray'}}>Sold Out</h3>
                        
                          {/* <button onClick={clickHandler} aria-label="Button Title" type="submit" className="theme-btn subscribe-btn">
                          Clic aquí
                        </button> */}
                        </>)}
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default App;
