import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/business-1.jpg'
import abimg2 from '../../images/resource/business-2.jpg'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one" id='about'>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                <div className="sec-title_title">Become Succesfuly day</div>
                                <h2 className="sec-title_heading">The Most inspirational Conference For Business.</h2>
                            </div>
                            <div className="business-one_bold-text">Grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures together</div>
                            <div className="business-one_text">We’re inviting the top creatives in the tech industry from all over the world to come learn, grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures together, this time both in-person</div>
                            <div className="business-one_feature">
                                <div className="business-one_feature-icon flaticon-tourist"></div>
                                <strong>Exclusive Keynotes</strong>
                                Dolor sit amet consectetur elit sed eiusmod tempor in idunt cet.
                            </div>
                            <div className="business-one_feature">
                                <div className="business-one_feature-icon flaticon-leader"></div>
                                <strong>Off-Side Trips</strong>
                                Dolor sit amet consectetur elit sed eiusmod tempor in idunt cet.
                            </div>

                            <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Book Ticket</span>
                                        <span className="text-two">Book Ticket</span>
                                    </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div>
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;