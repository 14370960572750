import React from 'react';
import { Link } from 'react-router-dom'
import imgVer from '../../images/diplIFMvertical.png'
import imgVer2 from '../../images/diplIFMvertical2.png'
import modality from '../../images/modality.png'
import modality2 from '../../images/modality2.png'



const pricing = [
    {
        img: imgVer,
        img2: modality,
        li1: 'Conferencia Completa',
        li2: '(Incluye cafe,etc)',
        li3: 'Videos en directo',
        li4: '(Acceso gratuito en linea)',
        li5: 'Conocer al ponente',
        li6: '(Hacer preguntas en privado)',
        title: 'Early bird',
        price: '1.440',
        price2: 'USD $ 3.400',
        aliance:'gracias a nuestra alianza entre AFM y IFM',
        /* shape: rv1,
        shape2: rv2, */
        
    },
    {
        img: imgVer2,
        img2: modality2,
        li1: 'Conferencia Completa',
        li2: '(Incluye cafe,etc)',
        li3: 'Videos en directo',
        li4: '(Acceso gratuito en linea)',
        li5: 'Conocer al ponente',
        li6: '(Hacer preguntas en privado)',
        title: 'Justo a tiempo',
        price: '1.640',
        active: 'active',
        price2: '',
        /* shape: mv1,
        shape2: mv2, */
        
    },
    /* {
        li1: 'Full Conference',
        li2: '(Includes workshop, coffee etc)',
        li3: 'Live Videos',
        li4: '(Can access online free)',
        li5: 'Meet Speaker',
        li6: '(Ask questions privately)',
        title: 'Business Pass',
        price: '320',
        shape: lv1,
        shape2: lv2,
        day:'3',
    }, */


]


const Pricing = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="price-one">
            {/* <div className="price-one_pattern" style={{ backgroundImage: `url(${bg})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">ASEGURA TU LUGAR</div>
                    <h2 className="sec-title_heading">Paquetes de Estudio </h2>
                    <div className='event-dates'>
                    <p className="sec-title_text1">Precio válido del <span>5 de enero</span> <br/> al <span>6 de marzo</span> de 2024</p>
                    <p className="sec-title_text2">Precio válido del <span>6 de marzo de 2024</span> <br/> al cierre de inscripciones.</p>

                    </div>
                </div>
                <div className="row clearfix separated">
                    {pricing.map((pricing, ptem) => (
                        
                        <div className="price-block_one col-lg-6 col-md-4 col-sm-12" key={ptem}>
                            <div className="price-block_one-inner">
                                <div className="price-block_one-pattern" style={{ backgroundImage: `url(${pricing.shape})` }}></div>
                                <div className="price-block_one-pattern-two" style={{ backgroundImage: `url(${pricing.shape2})` }}></div>
                                <div className="price-block_one-title">{pricing.title}</div>
                                <img className='vertical-logo' src={pricing.img} alt=''/>
                                <img className='virtual-modality' src={pricing.img2} alt=''/>
                                <div className='strike'>{pricing.price2}</div>
                                <div className='aliance'>{pricing.aliance}</div>
                                <div className="price-block_one-price">USD ${pricing.price}</div>
                                {/* <ul className="price-block_one-options">
                                    <li><strong>{pricing.li1}</strong>{pricing.li2}</li>
                                    <li className="light"><strong>{pricing.li3}</strong>{pricing.li4}</li>
                                    <li className="light"><strong>{pricing.li5}</strong>{pricing.li6}</li>
                                </ul> */}
                                <div className="price-block_one-button">
                                    <a onClick={ClickHandler} href='https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/' target='_blank'rel='noreferrer' className="theme-btn btn-style-two">
                                        <span className="btn-wrap">
                                            <span className="text-one">Comprar</span>
                                            <span className="text-two">Comprar</span>
                                        </span>
                                    </a>
                                </div>
                                <div className="price-block_one-charges">* El precio final en moneda local dependerá del tipo de cambio de su entidad financiera (conversión USD - dólares americanos vs. moneda local de pago)</div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            
        </section>
    );
}

export default Pricing;