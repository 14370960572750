import React from "react";
import video from "../../video/video.mp4";
import { Link } from "react-router-dom";

const Prueba = () => {
  return (
    <div className="container-video">
      <div className="overlay" />
      {/* <a
        href="https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2024-afmcp/"
        target="_blank"
        rel="noreferrer"
        class="btn-hero"
      >Inscríbete acá</a>  */}
      
      <video className="video-hero" src={video} autoPlay loop muted></video>
      
    </div>
  );
};

export default Prueba;
