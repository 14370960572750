import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3'
import Hero from '../../components/hero/hero';
import About from '../../components/about/about';
import FunFact from '../../components/FunFact';
import Speakers from '../../components/Speakers/Speakers';
import Features from '../../components/Features';
import EventsSection from '../../components/EventsSection/EventsSection';
import GallerySection from '../../components/GallerySection/GallerySection';
import Pricing from '../../components/Pricing/Pricing';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import Subscribe from '../../components/Subscribe/Subscribe';
import BlogSection from '../../components/BlogSection/BlogSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePageOnePage = () => {
    return (
        <Fragment>
            <Navbar3 />
            <Hero />
            <About />
            <FunFact />
            <Speakers />
            <Features />
            <EventsSection />
            <GallerySection />
            <Pricing />
            <VideoArea />
            <PartnerSection />
            <BlogSection />
            <Subscribe />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePageOnePage;