import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
/* import abimg from '../../images/background/pattern-1.png'
import abimg2 from '../../images/background/pattern-2.png'
import abimg3 from '../../images/background/pattern-3.png' */
/* import abimg4 from '../../images/background/about-pattern.png' */
import abimg5 from '../..//images/resource/about.png'
import { Roll } from "react-awesome-reveal";
import bannerAbout from '../../images/resource/banner-about.png';
import bannerAbout1 from '../../images/resource/banner-about1.png';
import useResponsiveLink from '../../Hooks/useResponsiveLink';

const About = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [isOpen, setOpen] = useState(false)

    const deviceLink = useResponsiveLink()
    
    return (

        <section className="about-one" id="about">
            <div className='container-bannerAbout'>
                <img className='bannerAbout' src={bannerAbout} alt='' />
                <img className='bannerAbout1' src={bannerAbout1} alt='' />
            </div>
            {/*  <div className="about-one_pattern-one" style={{ backgroundImage: `url(${abimg})` }}></div>
            <div className="about-one_pattern-two" style={{ backgroundImage: `url(${abimg3})` }}></div>
            <div className="about-one_pattern-three" style={{ backgroundImage: `url(${abimg2})` }}></div> */}
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="about-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_image-inner">
                            {/* <div className="about-one_pattern-four" style={{ backgroundImage: `url(${abimg4})` }}></div> */}
                            <Roll left triggerOnce={'false'}>
                                <div className="about-one_image">
                                    <img src={abimg5} alt="" />
                                </div>
                            </Roll>
                            <button onClick={() => setOpen(true)} className="lightbox-video slider-one_play"><span className="fa fa-play"><i className="ripple"></i></span></button>
                        </div>
                    </div>
                    <div className="about-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_content-inner">
                            <div className="sec-title title-anim">
                                {/* <div className="sec-title_title">About Event</div> */}
                                <h2 className="sec-title_heading">Diplomado de medicina funcional</h2>
                            </div>
                            {/* <div className="about-one_bold-text">Grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures together</div> */}
                            <p className="about-text">Impulsa tu carrera médica con nuestro diplomado internacional de medicina funcional. Estudio flexible adaptado a ti: aprendizaje personalizado, acceso 24/7, sesiones virtuales en vivo y recursos multimedia.<br /> <br /> Avanza a tu ritmo con soporte experto continuo. Descubre más y eleva tu práctica profesional hoy.</p>
                            <div className="row clearfix">
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                                    <div className="about-one_block-inner">
                                        <span className="about-one_block-icon flaticon-calendar-2"></span>
                                        <strong>Fecha</strong>
                                        <strong>1 de abril al 15 de noviembre</strong> <br />
                                        <span className='bolder'>AFMCP</span> - 1 de abril al 02 de junio <br />
                                        <span className='bolder'>Diplomado</span> - 8 de julio al 15 noviembre
                                    </div>
                                </div>
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">

                                </div>
                            </div>
                            <div className="price-block_one-button">
                                <a href={deviceLink} target="_blank" rel="noreferrer" class="btn-about">Deseo saber más</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="pV9cFjJFmZ4" onClose={() => setOpen(false)} />
        </section>
    )
}

export default About;