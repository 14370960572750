import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
/* import bg1 from '../../images/main-slider/pattern-3.png'
import bg2 from '../../images/main-slider/pattern-4.png'
import bg3 from '../../images/main-slider/pattern-5.png'
import bg4 from '../../images/main-slider/cloud.png' */

/* import hero1 from '../../images/main-slider/content-image.png' */
import TimeCountDown from "../countdown";

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Hero2 = () => {

    var settings = {
        dots: true,
        arrows: false,
        speed: 2000,
        fade: true,
        loop: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (

        <section className="slider-two" id="home">
            <div className="main-slider-carousel">
                <Slider {...settings}>
                    <div className="slider-two_slide">
                        {/* <div className="slider-two_icon-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                        <div className="slider-two_icon-two" style={{ backgroundImage: `url(${bg2})` }}></div>
                        <div className="slider-two_icon-three" style={{ backgroundImage: `url(${bg3})` }}></div>
                        <div className="slider-two_cloud-layer" style={{ backgroundImage: `url(${bg4})` }}></div> */}
                        {/* <div className="slider-two_image-layer" style={{ backgroundImage: `url(${bg5})` }}></div> */}
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div className="slider-two_image-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="slider-two_image">
                                       {/*  <img src={hero1} alt="" /> */}
                                    </div>
                                </div>
                                <div className="slider-two_content col-lg-6 col-md-12 col-sm-12">
                                    <div className="slider-two_content-inner">
                                        <div className="slider-two_date">January 25, 2025</div>
                                        <h2 className="slider-two_heading">World Digital Conference 2025</h2>
                                        <div className="slider-two_location">Pearl Hotel, New York, USA</div>
                                        <div className="slider-two_button">
                                            <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-three">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Ticket</span>
                                                    <span className="text-two">Book Ticket</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="slider-two_slide">
                        {/* <div className="slider-two_cloud-layer" style={{ backgroundImage: `url(${bg4})` }}></div> */}
                       {/*  <div className="slider-two_image-layer" style={{ backgroundImage: `url(${bg5})` }}></div> */}
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div className="slider-two_image-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="slider-two_image">
                                       {/*  <img src={hero1} alt="" /> */}
                                    </div>
                                </div>
                                <div className="slider-two_content col-lg-6 col-md-12 col-sm-12">
                                    <div className="slider-two_content-inner">
                                        <div className="slider-two_date">January 25, 2025</div>
                                        <h2 className="slider-two_heading">World Digital Conference 2025</h2>
                                        <div className="slider-two_location">Pearl Hotel, New York, USA</div>
                                        <div className="slider-two_button">
                                            <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-three">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Ticket</span>
                                                    <span className="text-two">Book Ticket</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="slider-two_slide">
                       {/*  <div className="slider-two_cloud-layer" style={{ backgroundImage: `url(${bg4})` }}></div> */}
                       {/*  <div className="slider-two_image-layer" style={{ backgroundImage: `url(${bg5})` }}></div> */}
                        <div className="auto-container">
                            <div className="row clearfix">
                                <div className="slider-two_image-column col-lg-6 col-md-12 col-sm-12">
                                    <div className="slider-two_image">
                                       {/*  <img src={hero1} alt="" /> */}
                                    </div>
                                </div>
                                <div className="slider-two_content col-lg-6 col-md-12 col-sm-12">
                                    <div className="slider-two_content-inner">
                                        <div className="slider-two_date">January 25, 2025</div>
                                        <h2 className="slider-two_heading">World Digital Conference 2025</h2>
                                        <div className="slider-two_location">Pearl Hotel, New York, USA</div>
                                        <div className="slider-two_button">
                                            <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-three">
                                                <span className="btn-wrap">
                                                    <span className="text-one">Book Ticket</span>
                                                    <span className="text-two">Book Ticket</span>
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="slider-two_timer">
                <div className="time-countdown clearfix">
                    <TimeCountDown/>
                </div>
            </div>
        </section>
    )
}

export default Hero2;