import React, { useState, useEffect } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";

const menus = [
    {
        id: 4,
        title: 'Educadores',
        link: '#section1',
    },
    {
        id: 6,
        title: 'Etapas de pago',
        link: '#section2',
    },
    {
        id: 7,
        title: 'Certificaciones',
        link: '#section3',
    },
    {
        id: 8,
        title: 'Contacto',
        link: '#section4',
    },
];

const MobileMenu = () => {
    const [menuActive, setMenuState] = useState(false);

    const handleScrollToSection = (link) => {
        const section = document.querySelector(link);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth',
            });
            setMenuState(false); // Cierra el menú móvil después de seleccionar una sección
        }
    };

    return (
        <div className={`mobile-menu-wrap ${menuActive ? "mobile-menu-visible" : ""}`}>
            <div className="mobile-nav-toggler" onClick={() => setMenuState(!menuActive)}>
                <span className="icon flaticon-menu"></span>
            </div>
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={() => setMenuState(!menuActive)}></div>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}>
                    <span className="icon flaticon-multiply"></span>
                </div>
                <nav className="menu-box">
                    <List component="nav" className="navigation">
                        {menus.map((item) => (
                            <ListItem button key={item.id} onClick={() => handleScrollToSection(item.link)}>
                                {item.title}
                            </ListItem>
                        ))}
                    </List>
                </nav>
            </div>
        </div>
    );
};

export default MobileMenu;
