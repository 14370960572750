import React from 'react'
import { Link } from 'react-router-dom'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Logo from '../../images/logo.png'
import shape1 from '../../images/icons/icon-5.png'
import shape2 from '../../images/icons/icon-6.png'
import shape3 from '../../images/icons/icon-7.png'
import shape4 from '../../images/icons/icon-8.png'
import shape5 from '../../images/icons/icon-9.png'
import shape6 from '../../images/icons/icon-10.png'


import pImg1 from '../../images/gallery/1.jpg'
import pImg2 from '../../images/gallery/2.jpg'
import pImg3 from '../../images/gallery/3.jpg'
import pImg4 from '../../images/gallery/4.jpg'
import pImg5 from '../../images/gallery/5.jpg'
import pImg6 from '../../images/gallery/6.jpg'
import pImg7 from '../../images/gallery/7.jpg'
import pImg8 from '../../images/gallery/8.jpg'

const images = [
    pImg1,
    pImg2,
    pImg3,
    pImg4,
    pImg5,
    pImg6,
    pImg7,
    pImg8,
]


const Footer2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const [open, setOpen] = React.useState(false);

    return (
        <footer className="footer-two">
            <div className="footer-two_icon-one" style={{ backgroundImage: `url(${shape1})` }}></div>
            <div className="footer-two_icon-two" style={{ backgroundImage: `url(${shape1})` }}></div>
            <div className="footer-two_icon-three" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="footer-two_icon-four" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="footer-two_icon-five" style={{ backgroundImage: `url(${shape3})` }}></div>
            <div className="footer-two_icon-six" style={{ backgroundImage: `url(${shape4})` }}></div>
            <div className="footer-two_icon-seven" style={{ backgroundImage: `url(${shape5})` }}></div>
            <div className="footer-two_icon-eight" style={{ backgroundImage: `url(${shape6})` }}></div>
            <div className="auto-container">
                <div className="footer-two-widgets">
                    <div className="row clearfix">
                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="footer-two_column col-lg-7 col-md-6 col-sm-12">
                                    <div className="footer-two-widget logo-widget">
                                        <div className="footer-two_logo">
                                            <Link onClick={ClickHandler} to="/" ><img src={Logo} alt="" /></Link>
                                        </div>
                                        <div className="footer-two_text">We have very good strength in innovative technology and tools with over 35 years of experience. We makes long-term investments goal in global companies in different sectors, mainly in Europe and other countries.</div>
                                    </div>
                                </div>
                                <div className="footer-two_column col-lg-5 col-md-6 col-sm-12">
                                    <div className="footer-two-widget links-widget">
                                        <h4 className="footer-two_title">Links</h4>
                                        <ul className="footer-two_list">
                                            <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">About</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">Services</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">Projects</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">Blogs</Link></li>
                                            <li><Link onClick={ClickHandler} to="/">Contact</Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="big-column col-lg-6 col-md-12 col-sm-12">
                            <div className="row clearfix">
                                <div className="main-footer_column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget news-widget">
                                        <h4 className="footer-two_title">Information</h4>
                                        <ul className="footer-two_contact-list">
                                            <li><i className="icon fa fa-phone"></i>+92 ( 8800 ) - 6780</li>
                                            <li><i className="icon fa fa-envelope"></i> <a href="mailto:needhelp@company.com">needhelp@company.com</a></li>
                                            <li><i className="icon fa fa-map-marker"></i>30 broklyn golden street. New York</li>
                                        </ul>
                                        <ul className="footer-two_socials">
                                            <li><Link onClick={ClickHandler} to="/" className="fa fa-twitter"></Link></li>
                                            <li><Link onClick={ClickHandler} to="/" className="fa fa-facebook"></Link></li>
                                            <li><Link onClick={ClickHandler} to="/" className="fa fa-dribbble"></Link></li>
                                            <li><Link onClick={ClickHandler} to="/" className="fa fa-linkedin"></Link></li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="main-footer_column col-lg-6 col-md-6 col-sm-12">
                                    <div className="footer-widget instagram-widget">
                                        <h4 className="footer-two_title">instagram</h4>
                                        <div className="images-outer clearfix">
                                            {images.slice(0, 6).map((item, pitem) => (
                                                <figure className="image-box" key={pitem} onClick={() => setOpen(true)}><img src={item} alt="" /></figure>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <div className="copyright">&copy;  Copyright ©2023 noor_tech. All Rights Reserved</div>
                        </div>
                        <div className="column col-lg-6 col-md-12 col-sm-12">
                            <ul className="footer-bottom-nav">
                                <li><Link onClick={ClickHandler} to="/terms">Terms and conditions</Link></li>
                                <li><Link onClick={ClickHandler} to="/privacy">Privacy policy</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: pImg1 },
                    { src: pImg2 },
                    { src: pImg3 },
                    { src: pImg4 },
                    { src: pImg5 },
                    { src: pImg6 },
                ]}
                plugins={[Zoom]}
            />

        </footer>
    )
}

export default Footer2;