import React from 'react'
import { Link } from 'react-router-dom'



const Subscribe = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <section className="subscribe-one">
            {/* <div className="subscribe-one_pattern-three" style={{ backgroundImage: `url(${Sub1})` }}></div> */}
            <div className="auto-container">
                <div className="inner-container">
                    {/* <div className="subscribe-one_pattern" style={{ backgroundImage: `url(${Sub2})` }}></div>
                    <div className="subscribe-one_pattern-two" style={{ backgroundImage: `url(${Sub3})` }}></div> */}
                    <div className="row clearfix">
                        <div className="subscribe-one_title-column col-lg-6 col-md-12 col-sm-12">
                            {/* <div className="subscribe-one_title">Get The Latest Updates</div> */}
                            <h5 className="subscribe-one_heading">¡Da un paso hacia tu crecimiento profesional y lleva tu conocimiento a un nuevo nivel!</h5>
                        </div>

                        <div className="form-column col-lg-6 col-md-12 col-sm-12">
                            <div className="subscribe-box">
                                <form onSubmit={SubmitHandler}>
                                <h5 className="subscribe-one_heading">DIPLOMADO DE MEDICINA FUNCIONAL 2024</h5>
                                <hr/>
                                    {/* <div className="form-group">
                                        <input type="email" name="search-field" value="" placeholder="Your Email Address ........" required />
                                        <button type="submit">Subscribe</button>
                                    </div> */}
                                    <Link to="/register" className="theme-btn btn-style-one">
                        <span className="btn-wrap">
                            
                            <span className="text-one">Inscríbete aquí</span>
                            <span className="text-two">Inscríbete aquí</span>
                        </span>
                    </Link>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Subscribe;