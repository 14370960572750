import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom'
import Events from '../../api/Events';

import sIcon1 from '../../images/icons/icon-1.png'
import sIcon2 from '../../images/icons/icon-2.png'
import sIcon3 from '../../images/icons/icon-3.png'
import sIcon4 from '../../images/icons/icon-4.png'
import sIcon5 from '../../images/background/pattern-10.png'

const EventsSection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <section className="leadership-one" id='events'>
            <div className="leadership-one_icon" style={{ backgroundImage: `url(${sIcon1})` }}></div>
            <div className="leadership-one_icon-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
            <div className="leadership-one_icon-three" style={{ backgroundImage: `url(${sIcon3})` }}></div>
            <div className="leadership-one_icon-four" style={{ backgroundImage: `url(${sIcon4})` }}></div>
            <div className="leadership-one_icon-five" style={{ backgroundImage: `url(${sIcon5})` }}></div>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">Thought Leadership</div>
                    <h2 className="sec-title_heading">Join The World's Leading Companies <br /> at Technology Conference</h2>
                </div>
                <div className="leadership-info-tabs">
                    <div className="leadership-tabs tabs-box">
                        <ul className="">
                            <Nav tabs className="tab-btns tab-buttons clearfix">
                                {Events.slice(0, 6).map((event, i) => (
                                    <NavItem className='tab-btn' key={i}>
                                        <NavLink
                                            className={classnames({ active: activeTab === event.Id })}
                                            onClick={() => { toggle(event.Id); }}
                                        >
                                            {event.day} <span>{event.date}</span>
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </ul>
                        <div className="tabs-content">
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId='1'>
                                    {Events.slice(0, 3).map((event, i) => (
                                        <div className="content" key={i}>
                                            <div className="leadership-one_block">
                                                <div className="leadership-one_block-inner">
                                                    <div className="leadership-one_block-content">
                                                        <div className="leadership-one_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-one_block-icon"><i className={`fi ${event.Icon}`}></i></div>
                                                        </div>
                                                        <div className="leadership-one_block-time">{event.time}</div>
                                                        <div className="leadership-one_block-name">{event.speaker}</div>
                                                        <h3 className="leadership-one_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                        <div className="leadership-one_block-text">{event.des}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </TabPane>
                                <TabPane tabId='2'>
                                    {Events.slice(2, 4).map((event, eb2) => (
                                        <div className="content" key={eb2}>
                                            <div className="leadership-one_block">
                                                <div className="leadership-one_block-inner">
                                                    <div className="leadership-one_block-content">
                                                        <div className="leadership-one_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-one_block-icon"><i className={`fi ${event.Icon}`}></i></div>
                                                        </div>
                                                        <div className="leadership-one_block-time">{event.time}</div>
                                                        <div className="leadership-one_block-name">{event.speaker}</div>
                                                        <h3 className="leadership-one_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                        <div className="leadership-one_block-text">{event.des}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </TabPane>
                                <TabPane tabId='3'>
                                    {Events.slice(1, 4).map((event, eb3) => (
                                        <div className="content" key={eb3}>
                                            <div className="leadership-one_block">
                                                <div className="leadership-one_block-inner">
                                                    <div className="leadership-one_block-content">
                                                        <div className="leadership-one_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-one_block-icon"><i className={`fi ${event.Icon}`}></i></div>
                                                        </div>
                                                        <div className="leadership-one_block-time">{event.time}</div>
                                                        <div className="leadership-one_block-name">{event.speaker}</div>
                                                        <h3 className="leadership-one_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                        <div className="leadership-one_block-text">{event.des}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </TabPane>
                                <TabPane tabId='4'>
                                    {Events.slice(2, 4).map((event, eb3) => (
                                        <div className="content" key={eb3}>
                                            <div className="leadership-one_block">
                                                <div className="leadership-one_block-inner">
                                                    <div className="leadership-one_block-content">
                                                        <div className="leadership-one_block-image">
                                                            <img src={event.eImg} alt="" />
                                                            <div className="leadership-one_block-icon"><i className={`fi ${event.Icon}`}></i></div>
                                                        </div>
                                                        <div className="leadership-one_block-time">{event.time}</div>
                                                        <div className="leadership-one_block-name">{event.speaker}</div>
                                                        <h3 className="leadership-one_block-title"><Link onClick={ClickHandler} to={`/event-details/${event.slug}`}>{event.eTitle}</Link></h3>
                                                        <div className="leadership-one_block-text">{event.des}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EventsSection;