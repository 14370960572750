import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import About from '../../components/about/about';
import FunFact from '../../components/FunFact';
import Features from '../../components/Features';
import WhyAttendS2 from '../../components/WhyAttendS2/WhyAttendS2';
import Business from '../../components/Business/Business';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import VenueSection from '../../components/VenueSection/VenueSection';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';

const AboutPage = (props) => {

    return (
        <Fragment>
            <Navbar />
            <PageTitle pageTitle={'About Us'} pagesub={'About Us'} pageTitle2={'About Us'}/>
            <About />
            <FunFact />
            <Features />
            <WhyAttendS2 />
            <Business />
            <VideoArea />
            <PartnerSection />
            <VenueSection />
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
    )
};
export default AboutPage;
