import React from 'react'
import { Link } from 'react-router-dom'
import Teams from '../../api/Teams'
import shape1 from '../../images/background/pattern-20.png'

const Speakers2 = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="team-one" id='speakers'>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">Our Speakers</div>
                    <h2 className="sec-title_heading">Speakers Exparts</h2>
                </div>
                <div className="row clearfix">

                    {Teams.slice(0, 6).map((team, aitem) => (
                        <div className="team-block_one col-lg-4 col-md-6 col-sm-12"  key={aitem}>
                            <div className="team-block_one-inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="team-block_one-pattern" style={{ backgroundImage: `url(${shape1})` }}></div>
                                <div className="team-block_one-image">
                                    <img src={team.tsImg} alt="" />
                                    <div className="team-block_one-social-list">
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="facebook fa fa-facebook"></Link>
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="youtube fi flaticon-youtube"></Link>
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="twitter fi flaticon-instagram"></Link>
                                        <Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`} className="twitter fa fa-brands fa-twitter"></Link>
                                    </div>
                                </div>
                                <div className="team-block_one-content">
                                    <h4 className="team-block_one-title"><Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`}>{team.name}</Link></h4>
                                    <div className="team-block_one-designation">{team.title}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Speakers2;
