import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import mImg1 from '../../images/background/2.jpg'
import mImg2 from '../../images/background/pattern-11.png'
import mImg3 from '../../images/icons/icon-3.png'
import mImg4 from '../../images/icons/icon-2.png'
import mImg5 from '../../images/icons/icon-1.png'

const VideoArea = (props) => {

    const [isOpen, setOpen] = useState(false)


    return (
        <section className="video-one" style={{ backgroundImage: `url(${mImg1})` }}>
            <div className="video-one_pattern-one" style={{ backgroundImage: `url(${mImg2})` }}></div>
            <div className="video-one_pattern-two" style={{ backgroundImage: `url(${mImg3})` }}></div>
            <div className="video-one_pattern-three" style={{ backgroundImage: `url(${mImg4})` }}></div>
            <div className="video-one_pattern-four" style={{ backgroundImage: `url(${mImg5})` }}></div>
            <div className="auto-container">
                <div className="sec-title title-anim light centered">
                    <div className="sec-title_title">Conference Ticket Price</div>
                    <h2 className="sec-title_heading">We’re Always in The Lead.	<br /> From The Business Conference!</h2>
                    <button onClick={() => setOpen(true)} className="lightbox-video video-one_play"><span className="fa fa-play"><i className="ripple"></i></span></button>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="YS3PwmOQ1Fc" onClose={() => setOpen(false)} />
        </section>
    );
}

export default VideoArea;