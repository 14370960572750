import React from 'react'
import CountUp from 'react-countup';
import bg from '../../images/background/destellosYCirulos.jpg'

const FunFact = (props) => {

    const funFact = [
        {
            title: '15',
            subsymbol:' ',
            subTitle: 'Docentes pioneros y líderes en medicina funcional',
            Symbol: '',
        },
        {
            title: '160',
            subsymbol:'',
            subTitle: 'Horas de contenido y material de estudio',
            Symbol: '',
        },
        {
            title: '600',
            subsymbol:' ',
            subTitle: 'Participantes del AFMCP en español',
            Symbol: '+',
        },
        {
            title: '1650',
            subsymbol:' ',
            subTitle: 'Súmate a nuestra comunidad',
            Symbol: '+',
        },


    ]

    return (

        <section className="counter-one">
            <div className="auto-container">
                <div className="inner-container">
                    <div className="counter-one_image" style={{ backgroundImage: `url(${bg})` }}></div>
                    <div className="row clearfix">
                        {funFact.map((funfact, fitem) => (
                            <div className="counter-one_block col-lg-3 col-md-6 col-sm-12" key={fitem}>
                                <div className="counter-one_inner">
                                    <div className="counter-one_counter">
                                        <h4>{funfact.Symbol} 
                                        <CountUp end={funfact.title} enableScrollSpy />
                                        {funfact.subsymbol}
                                        </h4>
                                    </div>
                                    <div className="counter-one_text">{funfact.subTitle}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <span id="counter" className='d-none' />
        </section>

    )
}

export default FunFact;