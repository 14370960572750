import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import Hero2 from '../../components/hero2';
import About2 from '../../components/about2/about2';
import EventSubSec from '../../components/EventSubSec/EventSubSec';
import FunFact2 from '../../components/FunFact2/FunFact2';
import WhyAttend from '../../components/WhyAttend/WhyAttend';
import Speakers2 from '../../components/Speakers2/Speakers2';
import EventsSection2 from '../../components/EventsSection2/EventsSection2';
import FaqSection from '../../components/FaqSection/FaqSection';
import Pricing2 from '../../components/Pricing2/Pricing2';
import PartnerSection from '../../components/PartnerSection';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import BlogSection2 from '../../components/BlogSection2/BlogSection2';
import VenueSection from '../../components/VenueSection/VenueSection';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePageOnePage2 = () => {
    return (
        <Fragment>
            <Navbar3 />
            <Hero2 />
            <About2 />
            <EventSubSec />
            <FunFact2 />
            <WhyAttend />
            <Speakers2 />
            <EventsSection2 />
            <FaqSection />
            <Pricing2 />
            <BlogSection2 />
            <PartnerSection />
            <VenueSection />
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePageOnePage2;